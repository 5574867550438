@font-face {
	font-family: 'Poppins';
	src: local('Poppins'), url(fonts/Poppins/Poppins-Regular.ttf) format('truetype');
}

body {
	margin: 0;
	font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
		'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
	transition: background-color 600000s 0s, color 600000s 0s;
}
