.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
.MuiBadge-badge {
	right: -5px !important;
}

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}
.home,
/* .services, */
.advantages,
.discount,
.card,
.contacts,
.register,
.login {
	display: flex;
	height: 90vh;
	align-items: center;
	justify-content: center;
	font-size: 3rem;
}
/* .services {
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	color: #fff;
	font-size: 100px;
} */

.advantages {
	background-position: center;
	background-size: fill;
	background-repeat: no-repeat;
	color: #fff;
	font-size: 100px;
}

.register {
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	color: #fff;
	font-size: 100px;
}
.login {
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	color: #fff;
	font-size: 100px;
}

legend {
	white-space: nowrap;
}
